.rating-indicator {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: center;
  min-width: 120px;
}

.rating-indicator .bubble {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  border-radius: 50%;
  border: 1px solid #c3ced5;
}

.rating-indicator .info-circle {
  height: 15px;
  width: 15px;
  margin-left: 15px;
}

.rating-indicator .popup-remark {
  position: absolute;
  display: none;
  background-color: white;
  border: 1px solid #e6ecf0;
  padding: 10px 15px;
  max-height: 100px;
  overflow-y: auto;
  width: 203px;
  margin-left: -100px;
  margin-right: 13px;
  transform: translate(0, -50%);
  font-size: 14px;
  box-shadow: 2px 2px 20px -10px #aaa;
}

.rating-indicator .info-circle:hover + .popup-remark,
.rating-indicator .popup-remark:hover {
  display: block;
}

.rating-indicator.rating-1 > .bubble:nth-child(1) {
  background-color: #d50c2f;
}

.rating-indicator.rating-2 > .bubble:nth-child(1),
.rating-indicator.rating-2 > .bubble:nth-child(2) {
  background-color: #fbcdab;
}

.rating-indicator.rating-3 > .bubble:nth-child(1),
.rating-indicator.rating-3 > .bubble:nth-child(2),
.rating-indicator.rating-3 > .bubble:nth-child(3) {
  background-color: #ffe596;
}

.rating-indicator.rating-4 > .bubble:nth-child(1),
.rating-indicator.rating-4 > .bubble:nth-child(2),
.rating-indicator.rating-4 > .bubble:nth-child(3),
.rating-indicator.rating-4 > .bubble:nth-child(4) {
  background-color: #c2ddaf;
}
.rating-indicator.rating-5 > .bubble:nth-child(1),
.rating-indicator.rating-5 > .bubble:nth-child(2),
.rating-indicator.rating-5 > .bubble:nth-child(3),
.rating-indicator.rating-5 > .bubble:nth-child(4),
.rating-indicator.rating-5 > .bubble:nth-child(5) {
  background-color: #54a931;
}

.rating-indicator.editable:hover div.bubble {
  filter: brightness(0.9);
}

@keyframes loadingAnimation {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1);
  }
}

.rating-indicator.loading > .bubble:nth-child(1) {
  animation: loadingAnimation 1.1s linear 0s infinite forwards;
}
.rating-indicator.loading > .bubble:nth-child(2) {
  animation: loadingAnimation 1.1s linear 0.15s infinite forwards;
}
.rating-indicator.loading > .bubble:nth-child(3) {
  animation: loadingAnimation 1.1s linear 0.3s infinite forwards;
}
.rating-indicator.loading > .bubble:nth-child(4) {
  animation: loadingAnimation 1.1s linear 0.45s infinite forwards;
}
.rating-indicator.loading > .bubble:nth-child(5) {
  animation: loadingAnimation 1.1s linear 0.6s infinite forwards;
}
