.sidePopUp-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.205);
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.sidePopUp-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  animation: slideIn 0.2s cubic-bezier(0.3, 0, 0, 1) forwards;
}

.sidePopUp-body.sclose {
  animation: slideOut 0.2s cubic-bezier(0.3, 0, 0, 1) forwards;
}

.sidePopUp-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-bottom: 1px solid #ddd;
}

.sidePopUp-header div:first-child {
  width: 50px;
}

.sidePopUp-content {
  padding: 20px;
  margin: 50px 0 77px 0;
  overflow-y: auto;
}

.sidePopUp-content .non-form {
  box-sizing: border-box;
}

.sidePopUp-content .non-form p {
  white-space: pre-line;
}

.rdrMonth {
  width: 100% !important;
}

.sidePopUp-footer {
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

.sidePopUp-footer .button-group {
  margin-top: 0;
  width: 100%;
}

.sidePopUp-footer .button-group button {
  width: 100%;
}

.sidePopUp-footer .button-group .btn.undefined {
  margin-right: 0;
}

.rdrMonths.rdrMonthsVertical * {
  border-radius: 0 !important;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(450px);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(450px);
  }
  to {
    transform: translateX(0);
  }
}
