.create-subject h1 {
  border-bottom: 1px solid #c3ced5;
}

.create-subject-form {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
}

.create-subject .button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.create-subject .button-group button:first-child {
  margin-right: 20px;
}
