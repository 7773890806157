.btn {
  color: #fff;
  border: 0;
  background-color: #8fa2ac;
  padding: 6px 38px 5px;
  font-size: 18px;
  line-height: 22px;
  border-radius: 0;
  min-width: 130px;
  height: 36px;
  min-height: 36px;
  width: auto;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.hero {
  background-color: #a8005c;
}

.btn:disabled {
  color: #506671;
  background-color: #e6ecf0;
}

.btn:disabled:hover {
  background-color: #e6ecf0;
  cursor: not-allowed;
}

.btn:hover {
  background-color: #007caa;
  cursor: pointer;
}

.btn:disabled .lds-ring {
  background-color: #e6ecf0;
}

.btn:disabled .lds-ring div {
  border: 3px solid #bbb;
  border-color: #bbb #bbb #bbb transparent;
}
