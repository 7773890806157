.rating-points-overview .table tr {
  background-color: unset !important;
}

.rating-points-overview .table .category-title td,
.category-title-overview td {
  border-bottom: none;
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
}

.rating-points-overview .table tr td:first-child {
  text-align: left !important;
  width: 100%;
}

.rating-points-overview .table .rating-point b {
  font-size: 17px;
  font-weight: 600;
}

.rating-points-overview .table .rating-point p {
  margin-top: -3px;
  font-size: 16px;
}

.rating-points-overview .lock-icon {
  height: 15px;
  width: 15px;
}

.rating-points-overview .table td:nth-child(1) {
  max-width: 400px !important;
}

.rating-points-overview button {
  margin-left: 20px;
}

.rating-points-overview .state {
  font-size: 12px;
}

.rating-points-fillout .table tr td:last-child {
  text-align: left !important;
  display: flex;
  flex-direction: column;
}

.rating-points-fillout .table td textarea,
.category-title-overview td {
  min-height: 70px;
  max-height: 300px;
  min-width: 300px;
  max-width: 300px;
  font-size: 14px;
}

.downloadBtnPDF {
  cursor: pointer;
}
