.input-container .input-color {
  height: 38px;
  min-width: 38px;
  border: 1px solid #c3ced5;
  border-right: none;
}

.input,
.textarea {
  border: 1px solid #c3ced5;
  padding: 5px 10px;
  width: 100%;
  height: 38px;
  outline: none;
  font-size: 18px;
  width: 100%;
  max-width: 360px;
}

.input {
  max-height: 38px;
}

.textarea {
  resize: vertical;
  max-height: 300px;
}

.input:focus {
  border-color: #007caa;
}

.input.hasError {
  border-color: #d50c2f;
}

.input-error {
  margin-top: 10px;
  font-size: 14px;
  color: #d50c2f;
  font-style: italic;
  display: flex;
  align-items: center;
}

.input-error-icon {
  font-size: 16px;
  margin-right: 5px;
}

.input-select {
  max-width: 360px;
  width: 100%;
}

.input-select > div {
  border-radius: 0;
}

.input-select:hover {
  border-color: #c3ced5;
}

.input-select__control--is-focused {
  border: 1px solid #007caa !important;
  box-shadow: none !important;
}

.input-select__indicator > svg {
  fill: #a8005c;
}
