.spanConclusion {
  display: flex;
  justify-content: flex-start;
  max-width: max-content;
}

.spanKW {
  cursor: pointer;
  min-width: 100%;
}

.textareaWeeklyReport {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  font-size: 16px;
  resize: none;
  margin: 10px;
  padding: 10px;
  border: 1.5px solid #e6ecf0;
}

.textareaWeeklyReportKW {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 20%;
  border: 1px solid #e6ecf0;
  border-radius: 4px;
  resize: none;
}

.stylePen {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.dailyReportsOverview .table td:last-child {
  width: 5% !important;
}

.dailyReportsOverview .table td:nth-child(2) {
  max-width: 200px !important;
}

.dailyReportsOverview .table td:first-child {
  width: 16%;
  min-width: 50px;
}

.penEditConc {
  cursor: pointer;
}
.inputText {
  width: 16%;
  min-width: 50px;
}

.textareaDays {
  display: flex;
  justify-content: flex-end;
}

details > summary {
  background-color: #e6ecf0;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

details > summary > * {
  display: inline;
}

details > p {
  border: 2px solid #e6ecf0;
  margin-top: 0;
  padding: 1rem;
}

.txtAnswer {
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
  font-size: 16px;
  resize: none;
  margin: 10px;
  padding: 10px;
  border: 1.5px solid #e6ecf0;
}

p.pQuestion {
  width: 50%;
  padding: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

span.spanDailyRep {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.txtCalendarWeek {
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
  padding: 10px;
  border: 1.5px solid #e6ecf0;
  max-height: unset;
  min-height: unset;
  height: 48px;
}

div.Wochenfazit {
  margin-top: -30px;
}
