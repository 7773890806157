/* Cheyenne Sägesser */
.table td:last-child {
  width: 10%;
}

.table td:nth-child(2) {
  max-width: 50%;
}

.table td:first-child {
  width: 15%;
  min-width: 10%;
}
