.departmentCreate {
  padding-bottom: 50px;
}

.departmentCreate .button-group {
  margin-bottom: 25px;
}

.form.department-background_color {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
}

.react-colorful.department-color-picker {
  margin: 5px 0 0 0;
}

.react-colorful__pointer.react-colorful__saturation-pointer {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.react-colorful__pointer.react-colorful__hue-pointer {
  width: 16px;
  border-radius: 3px;
}

.required-fields {
  font-size: 12px;
}
