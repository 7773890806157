.grade-edit.form {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
}

.grade-edit .button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.grade-edit .button-group button:first-child {
  margin-right: 20px;
}
