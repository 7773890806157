.apprentice {
  border: 1px solid rgb(227, 227, 227);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  min-width: 280px;
  padding: 10px 15px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px -6px rgba(40, 40, 40, 0.6);
  margin: 30px 10px;
}

.apprentice,
.apprentice > * {
  cursor: pointer;
}

.apprentice .color-banner {
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: calc(100% + 30px);
  margin-top: -10px;
  margin-bottom: 30px;
  margin-left: -15px;
}

.apprentice .profile-img {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  position: absolute;
  margin-top: -22px;
  margin-left: -25px;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center center !important;
  box-shadow: 2px 2px 10px -5px #39393988;
}

.apprentice .name {
  margin-left: 73px;
  margin-top: -15px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 22px;
  max-height: 52px;
  overflow: hidden;
}

.apprentice .last-grade {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 4px 15px 0px 15px;
  max-height: 25px;
  overflow: hidden;
}

.apprentice .last-grade p {
  margin: 0;
}

.apprentice .edit-icon {
  opacity: 0;
  margin-left: 10px;
  font-size: 17px;
}

.apprentice:hover .edit-icon {
  opacity: 1;
}
