*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font: 100% 'EuH', arial, helvetica, sans-serif;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #ffffff;
}

.header-spacer {
  width: 100%;
  height: 1px;
  background-color: #fff;
  z-index: 10;
}

.cyan-stripe {
  border-left: 20px solid #00aeef;
}

.container {
  width: 100%;
  min-height: calc(100vh - 246px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 1100px;
  width: 100%;
  padding: 10px;
}

.title {
  border-bottom: 1px solid #c3ced5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-icon {
  font-size: 30px;
  color: #000;
}

.title-icon:hover {
  cursor: pointer;
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.button-group button:first-child {
  margin-right: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
}

.grades-average {
  margin-top: 40px;
}

@media screen and (max-width: 520px) {
  .cyan-stripe {
    border-left-width: 10px;
  }
}

textarea {
  min-height: 120px;
}

.notFound,
.forbidden {
  display: flex;
  align-items: center;
}

.notFound div,
.forbidden div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound img {
  max-width: 500px;
  max-height: 600px;
  height: auto;
  width: 100%;
  padding: 0 10px;
}

.forbidden img {
  max-width: fit-content;
  max-height: 400px;
  height: auto;
  width: 100%;
  padding: 0 10px;
}

.notFound p,
.forbidden p {
  margin: 10px 0 40px 0;
}

/* BREADCRUMB */

.breadcrumb-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.breadcrumb-container {
  white-space: nowrap;
  max-width: 1100px;
  width: 100%;
  padding: 20px 10px 0 10px;
}

.breadcrumb-container.bc-edit {
  max-width: 100%;
}

.breadcrumb-container .tippy-box.menu {
  padding: 5px 15px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 30%);
}

.breadcrumb-container .tippy-box.menu .list-item-action {
  padding: 5px 0;
}

.breadcrumb-container .tippy-box.menu .list-item-action button {
  width: 100%;
  text-align: left;
}

.breadcrumb-container .breadcrumb-list {
  display: flex;
  max-width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumb-container .breadcrumb-list > li {
  position: relative;
  display: flex;
  align-self: center;
  margin: 0 15px 0 0;
  font-size: 16px;
  letter-spacing: normal;
  vertical-align: top;
}

.breadcrumb-container .breadcrumb-list > li > button > i,
.breadcrumb-container .breadcrumb-list > li > i,
.breadcrumb-container .breadcrumb-list > li > button,
.breadcrumb-container .breadcrumb-icon:first-child > li > button > i {
  align-self: center !important;
}

.breadcrumb-container .breadcrumb-icon:first-child * {
  display: flex;
}

.breadcrumb-container .breadcrumb-list button {
  padding: 0;
  background: none;
  border: none;
}

.breadcrumb-container .bread-crumb-icon-right {
  margin: 0 0 0 15px;
  font-size: 9px;
  color: #c3ced5;
}

.breadcrumb-container .bread-crumb-icon-first {
  align-self: center;
  margin: 0;
  margin-right: 15px;
  font-size: 9px;
  color: #c3ced5;
}

.breadcrumb-container .breadcrumb-icon:first-child button i {
  font-size: 22px;
}

.breadcrumb-container .breadcrumb-item button,
.breadcrumb-container .breadcrumb-icon:first-child button,
.breadcrumb-container .list-item-action-menu button {
  font-weight: 400;
  color: #333;
  cursor: pointer;
}

.breadcrumb-container .breadcrumb-item button:hover,
.breadcrumb-container .breadcrumb-icon:first-child button:hover i,
.breadcrumb-container .list-item-action-menu button:hover {
  color: #007caa !important;
  text-decoration: underline;
}

.breadcrumb-container .breadcrumb-item > .bread-crumb-active {
  color: #007caa;
}

.breadcrumb-container .breadcrumb-item > .bread-crumb-active:hover {
  text-decoration: underline;
}

.breadcrumb-container .bread-crumb-icon-active {
  display: none;
}

.breadcrumb-container .breadcrumb-icon {
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.breadcrumb-container .breadcrumb-icon-active,
.breadcrumb-container .breadcrumb-icon-active i {
  color: #007caa;
}

.breadcrumb-container .breadcrumb-icon:hover {
  color: #007caa;
  text-decoration: none;
}

/* ------- E+H Styles ------- */

h1,
.h1 {
  font-family: 'EuH Serif';
  font-size: 34px;
  line-height: 48px;
  font-weight: 400;
  margin: 20px 0 40px 0;
}

h2,
.h2 {
  font-family: 'EuH Serif';
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  margin: 30px 0 10px 0;
}

h3,
.h3 {
  font-family: 'EuH';
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  margin: 40px 0 20px 0;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: 'EuH';
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  margin: 20px 0;
}

h1.is-blue,
h2.is-blue,
h3.is-blue,
h4.is-blue,
h5.is-blue,
h6.is-blue,
.h1.is-blue,
.h2.is-blue,
.h3.is-blue,
.h4.is-blue,
.h5.is-blue,
.h6.is-blue {
  color: #007caa;
}

p,
li,
dd,
dt,
table,
label,
input,
textarea,
blockquote {
  font-size: 18px;
  line-height: 25px;
  font-family: 'EuH';
  font-weight: 200;
}

p + p {
  margin-top: 20px;
}

p + img {
  margin-top: 20px;
}

img + p {
  margin-top: 20px;
}

blockquote {
  padding: 20px;
  font-family: 'EuH';
  font-weight: 400;
  font-style: italic;
  background: #e6ecf0;
}

/* table {
  width: 100%;
  padding: 10px;
  border-spacing: 0;
}

table thead {
  font-weight: 400;
  text-align: left;
}

table thead th {
  border-bottom: 1px solid #c3ced5;
  padding: 5px 5px 10px 5px;
}

table tbody {
  font-size: 90%;
}

table tbody tr td {
  padding: 5px;
}

table tbody tr:first-child td {
  padding: 10px 5px 5px 5px;
}

table tbody tr:last-child td {
  padding: 5px 5px 10px 5px;
}

table tfoot {
  font-size: 75%;
}

table tfoot td {
  border-top: 1px solid #c3ced5;
  padding: 10px 5px 5px 5px;
} */

a {
  color: #a8005c;
  text-decoration: none;
}

a:hover {
  color: #007caa;
}

p a {
  display: inline-block;
}

@media screen and (max-width: 640px) {
  .table-wrap {
    overflow-x: auto;
  }

  .table-wrap table {
    min-width: 500px;
    font-size: 14px;
  }
}

/*---- fonts ----*/

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansBol.ttf') format('ttf'),
    url('../res/fonts/EuH_SansBol.woff') format('woff'),
    url('../res/fonts/EuH_SansBol.eot') format('eot');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansBolIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SansBolIta.woff') format('woff'),
    url('../res/fonts/EuH_SansBolIta.eot') format('eot');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansDem.ttf') format('ttf'),
    url('../res/fonts/EuH_SansDem.woff') format('woff'),
    url('../res/fonts/EuH_SansDem.eot') format('eot');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansDemIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SansDemIta.woff') format('woff'),
    url('../res/fonts/EuH_SansDemIta.eot') format('eot');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansLig.ttf') format('ttf'),
    url('../res/fonts/EuH_SansLig.woff') format('woff'),
    url('../res/fonts/EuH_SansLig.eot') format('eot');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansLigIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SansLigIta.woff') format('woff'),
    url('../res/fonts/EuH_SansLigIta.eot') format('eot');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansReg.ttf') format('ttf'),
    url('../res/fonts/EuH_SansReg.woff') format('woff'),
    url('../res/fonts/EuH_SansReg.eot') format('eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EuH';
  src: url('../res/fonts/EuH_SansRegIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SansRegIta.woff') format('woff'),
    url('../res/fonts/EuH_SansRegIta.eot') format('eot');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifBol.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifBol.woff') format('woff'),
    url('../res/fonts/EuH_SerifBol.eot') format('eot');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifBolIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifBolIta.woff') format('woff'),
    url('../res/fonts/EuH_SerifBolIta.eot') format('eot');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifDem.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifDem.woff') format('woff'),
    url('../res/fonts/EuH_SerifDem.eot') format('eot');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifDemIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifDemIta.woff') format('woff'),
    url('../res/fonts/EuH_SerifDemIta.eot') format('eot');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifLig.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifLig.woff') format('woff'),
    url('../res/fonts/EuH_SerifLig.eot') format('eot');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifLigIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifLigIta.woff') format('woff'),
    url('../res/fonts/EuH_SerifLigIta.eot') format('eot');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifReg.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifReg.woff') format('woff'),
    url('../res/fonts/EuH_SerifReg.eot') format('eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EuH Serif';
  src: url('../res/fonts/EuH_SerifRegIta.ttf') format('ttf'),
    url('../res/fonts/EuH_SerifRegIta.woff') format('woff'),
    url('../res/fonts/EuH_SerifRegIta.eot') format('eot');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'eh-iconset-20';
  src: url('../res/fonts/eh-iconset-20.eot');
  src: url('../res/fonts/eh-iconset-20.eot?#iefix') format('embedded-opentype'),
    url('../res/fonts/eh-iconset-20.woff') format('woff'),
    url('../res/fonts/eh-iconset-20.ttf') format('truetype'),
    url('../res/fonts/eh-iconset-20.svg#eh-iconset-20') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'eh-iconset-20' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[className^='icon-']:before,
[className*=' icon-']:before {
  font-family: 'eh-iconset-20' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'eh-iconset-30';
  src: url('../res/fonts/eh-iconset-30.eot');
  src: url('../res/fonts/eh-iconset-30.eot?#iefix') format('embedded-opentype'),
    url('../res/fonts/eh-iconset-30.woff') format('woff'),
    url('../res/fonts/eh-iconset-30.ttf') format('truetype'),
    url('../res/fonts/eh-iconset-30.svg#eh-iconset-30') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'eh-iconset-30' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'eh-iconset-30' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eh-supplement-0:before {
  content: '\e229';
}
.icon-eh-supplement-0-superscript:before {
  content: '\e228';
}
.icon-eh-supplement-1:before {
  content: '\e231';
}
.icon-eh-supplement-1-superscript:before {
  content: '\e230';
}
.icon-eh-supplement-2:before {
  content: '\e233';
}
.icon-eh-supplement-2-superscript:before {
  content: '\e232';
}
.icon-eh-supplement-3:before {
  content: '\e235';
}
.icon-eh-supplement-3-superscript:before {
  content: '\e234';
}
.icon-eh-supplement-4:before {
  content: '\e237';
}
.icon-eh-supplement-4-superscript:before {
  content: '\e236';
}
.icon-eh-supplement-5:before {
  content: '\e239';
}
.icon-eh-supplement-5-superscript:before {
  content: '\e238';
}
.icon-eh-supplement-6:before {
  content: '\e241';
}
.icon-eh-supplement-6-superscript:before {
  content: '\e240';
}
.icon-eh-supplement-7:before {
  content: '\e243';
}
.icon-eh-supplement-7-superscript:before {
  content: '\e242';
}
.icon-eh-supplement-8:before {
  content: '\e245';
}
.icon-eh-supplement-8-superscript:before {
  content: '\e244';
}
.icon-eh-supplement-9:before {
  content: '\e247';
}
.icon-eh-supplement-9-superscript:before {
  content: '\e246';
}
.icon-eh-acceptance-rate:before {
  content: '\e070';
}
.icon-eh-add:before {
  content: '\e045';
}
.icon-eh-add-cart:before {
  content: '\e046';
}
.icon-eh-add-row-above:before {
  content: '\e071';
}
.icon-eh-add-row-below:before {
  content: '\e072';
}
.icon-eh-amplitude:before {
  content: '\e073';
}
.icon-eh-app-settings:before {
  content: '\e047';
}
.icon-eh-application:before {
  content: '\e048';
}
.icon-eh-approval:before {
  content: '\e049';
}
.icon-eh-arrow-down:before {
  content: '\e04a';
}
.icon-eh-arrow-left:before {
  content: '\e04b';
}
.icon-eh-arrow-long-down:before {
  content: '\e074';
}
.icon-eh-arrow-long-left:before {
  content: '\e075';
}
.icon-eh-arrow-long-right:before {
  content: '\e076';
}
.icon-eh-arrow-long-up:before {
  content: '\e077';
}
.icon-eh-arrow-right:before,
.icon-arrow-right:before {
  content: '\e04c';
}
.icon-eh-arrow-up:before {
  content: '\e04d';
}
.icon-eh-arrows-long-right-left:before {
  content: '\e04e';
}
.icon-eh-arrows-long-up-down:before {
  content: '\e04f';
}
.icon-eh-arrows-short-right-left:before {
  content: '\e050';
}
.icon-eh-attachment:before {
  content: '\e051';
}
.icon-eh-backspace:before {
  content: '\e078';
}
.icon-eh-backward:before {
  content: '\e039';
}
.icon-eh-batch-1:before {
  content: '\e079';
}
.icon-eh-batch-2:before {
  content: '\e080';
}
.icon-eh-batch-3:before {
  content: '\e081';
}
.icon-eh-batch-4:before {
  content: '\e082';
}
.icon-eh-batch-5:before {
  content: '\e083';
}
.icon-eh-battery-1:before {
  content: '\e03a';
}
.icon-eh-battery-2:before {
  content: '\e03b';
}
.icon-eh-battery-3:before {
  content: '\e03c';
}
.icon-eh-battery-4:before {
  content: '\e03d';
}
.icon-eh-bluetooth:before {
  content: '\e294';
}
.icon-eh-bookmark:before {
  content: '\e295';
}
.icon-eh-bookmark-filled:before {
  content: '\e040';
}
.icon-eh-cable-connection-failure:before {
  content: '\e041';
}
.icon-eh-cable-connection-ok:before {
  content: '\e042';
}
.icon-eh-cad-view-bottom:before {
  content: '\e043';
}
.icon-eh-cad-view-default:before {
  content: '\e044';
}
.icon-eh-cad-view-front:before {
  content: '\e296';
}
.icon-eh-cad-view-left:before {
  content: '\e297';
}
.icon-eh-cad-view-right:before {
  content: '\e298';
}
.icon-eh-cad-view-top:before {
  content: '\e299';
}
.icon-eh-calendar-day:before {
  content: '\e085';
}
.icon-eh-calendar-empty:before {
  content: '\e086';
}
.icon-eh-calendar-week:before {
  content: '\e087';
}
.icon-eh-calendar-year:before {
  content: '\e088';
}
.icon-eh-camera:before {
  content: '\e031';
}
.icon-eh-cart:before {
  content: '\e033';
}
.icon-eh-chart-position:before {
  content: '\e034';
}
.icon-eh-check:before {
  content: '\e035';
}
.icon-eh-check-device-details:before {
  content: '\e067';
}
.icon-eh-checkbox-checked:before {
  content: '\e052';
}
.icon-eh-checkbox-inactive:before {
  content: '\e053';
}
.icon-eh-checkbox-unchecked:before {
  content: '\e054';
}
.icon-eh-coating:before {
  content: '\e090';
}
.icon-eh-comment:before {
  content: '\e091';
}
.icon-eh-compare:before {
  content: '\e036';
}
.icon-eh-complete:before {
  content: '\e037';
}
.icon-eh-conditions:before {
  content: '\e038';
}
.icon-eh-conductivity:before {
  content: '\e092';
}
.icon-eh-confirmation:before {
  content: '\e062';
}
.icon-eh-controller:before {
  content: '\e093';
}
.icon-eh-copy:before {
  content: '\e01f';
}
.icon-eh-corrected-conductivity:before {
  content: '\e094';
}
.icon-eh-corrected-volume-flow:before {
  content: '\e095';
}
.icon-eh-cut:before {
  content: '\e021';
}
.icon-eh-damping:before {
  content: '\e096';
}
.icon-eh-database:before {
  content: '\e022';
}
.icon-eh-debug:before {
  content: '\e023';
}
.icon-eh-delete:before {
  content: '\e024';
}
.icon-eh-delete-character-left:before {
  content: '\e097';
}
.icon-eh-delete-character-right:before {
  content: '\e098';
}
.icon-eh-delete-row:before {
  content: '\e099';
}
.icon-eh-delivery:before {
  content: '\e025';
}
.icon-eh-delta:before {
  content: '\e100';
}
.icon-eh-density:before {
  content: '\e101';
}
.icon-eh-diagnose:before {
  content: '\e026';
}
.icon-eh-difference-level:before {
  content: '\e102';
}
.icon-eh-document:before {
  content: '\e027';
}
.icon-eh-documents:before {
  content: '\e028';
}
.icon-eh-duplicate:before {
  content: '\e029';
}
.icon-eh-dynamically-viscosity:before {
  content: '\e103';
}
.icon-eh-edit:before {
  content: '\e02b';
}
.icon-eh-edit-table:before {
  content: '\e02a';
}
.icon-eh-enter:before {
  content: '\e01e';
}
.icon-eh-escape:before {
  content: '\e01d';
}
.icon-eh-exponent-base:before {
  content: '\e104';
}
.icon-eh-exponent-minus:before {
  content: '\e105';
}
.icon-eh-exponent-plus:before {
  content: '\e106';
}
.icon-eh-exponent-supplement-minus:before {
  content: '\e107';
}
.icon-eh-exponent-supplement-plus:before {
  content: '\e108';
}
.icon-eh-export:before {
  content: '\e01c';
}
.icon-eh-external-link:before {
  content: '\e01b';
}
.icon-eh-facebook:before {
  content: '\e01a';
}
.icon-eh-favorit:before {
  content: '\e019';
}
.icon-eh-favorit-filled:before {
  content: '\e018';
}
.icon-eh-fill-level-high:before {
  content: '\e109';
}
.icon-eh-fill-level-low:before {
  content: '\e110';
}
.icon-eh-fill-level-medium:before {
  content: '\e111';
}
.icon-eh-filter:before {
  content: '\e017';
}
.icon-eh-flag:before {
  content: '\e016';
}
.icon-eh-folder:before {
  content: '\e015';
}
.icon-eh-forward:before {
  content: '\e014';
}
.icon-eh-frequence:before {
  content: '\e113';
}
.icon-eh-good:before {
  content: '\e013';
}
.icon-eh-googleplus:before {
  content: '\e012';
}
.icon-eh-gradient-falling:before {
  content: '\e115';
}
.icon-eh-gradient-rising:before {
  content: '\e116';
}
.icon-eh-grid-off:before {
  content: '\e005';
}
.icon-eh-grid-on:before {
  content: '\e006';
}
.icon-eh-grid-view:before {
  content: '\e007';
}
.icon-eh-guidance:before {
  content: '\e008';
}
.icon-eh-heat-flow:before {
  content: '\e117';
}
.icon-eh-help:before {
  content: '\e00a';
}
.icon-eh-hide-chart:before {
  content: '\e00b';
}
.icon-eh-hide-details:before {
  content: '\e00c';
}
.icon-eh-hide-legend:before {
  content: '\e00d';
}
.icon-eh-hide-record:before {
  content: '\e00e';
}
.icon-eh-history:before {
  content: '\e00f';
}
.icon-eh-hold:before {
  content: '\e118';
}
.icon-eh-home:before,
.icon-home:before {
  content: '\e010';
}
.icon-eh-image:before {
  content: '\e011';
}
.icon-eh-import:before {
  content: '\e004';
}
.icon-eh-industries:before {
  content: '\e003';
}
.icon-eh-info:before {
  content: '\e002';
}
.icon-eh-language:before {
  content: '\e125';
}
.icon-eh-link:before {
  content: '\e127';
}
.icon-eh-linkedin:before {
  content: '\e128';
}
.icon-eh-kinematically-viscosity:before {
  content: '\e124';
}
.icon-eh-jpg:before {
  content: '\e05a';
}
.icon-eh-is-geo-location:before {
  content: '\e123';
}
.icon-eh-interface-level:before {
  content: '\e122';
}
.icon-eh-interface-distance:before {
  content: '\e121';
}
.icon-eh-instagram:before {
  content: '\e069';
}
.icon-eh-input:before {
  content: '\e120';
}
.icon-eh-info-no-circle:before {
  content: '\e000';
}
.icon-eh-info-gray:before {
  content: '\e001';
}
.icon-eh-list:before {
  content: '\e129';
}
.icon-eh-mass-flow:before {
  content: '\e141';
}
.icon-eh-mute:before {
  content: '\e152';
}
.icon-eh-livelist:before {
  content: '\e130';
}
.icon-eh-lkw:before {
  content: '\e064';
}
.icon-eh-location:before {
  content: '\e131';
}
.icon-eh-locked:before {
  content: '\e132';
}
.icon-eh-login:before {
  content: '\e133';
}
.icon-eh-lower-case:before {
  content: '\e134';
}
.icon-eh-lower-limit:before {
  content: '\e135';
}
.icon-eh-mail:before {
  content: '\e136';
}
.icon-eh-mains-operated:before {
  content: '\e137';
}
.icon-eh-manage-cleanups:before {
  content: '\e138';
}
.icon-eh-manual-operation:before {
  content: '\e139';
}
.icon-eh-mass-concentration:before {
  content: '\e140';
}
.icon-eh-movie:before {
  content: '\e151';
}
.icon-eh-page-up:before {
  content: '\e163';
}
.icon-eh-page-down:before {
  content: '\e162';
}
.icon-eh-output:before {
  content: '\e161';
}
.icon-eh-opacity:before {
  content: '\e160';
}
.icon-eh-onscreen-keyboard:before {
  content: '\e159';
}
.icon-eh-offset:before {
  content: '\e158';
}
.icon-eh-numbers:before {
  content: '\e157';
}
.icon-eh-notification:before {
  content: '\e156';
}
.icon-eh-noise:before {
  content: '\e155';
}
.icon-eh-no-processing:before {
  content: '\e154';
}
.icon-eh-nfc:before {
  content: '\e153';
}
.icon-eh-my-project:before {
  content: '\e066';
}
.icon-eh-math-characters:before {
  content: '\e142';
}
.icon-eh-menu:before {
  content: '\e143';
}
.icon-eh-inhomogeneous-bubbles:before {
  content: '\e119';
}
.icon-eh-micro-bubbles:before {
  content: '\e144';
}
.icon-eh-migrate:before {
  content: '\e145';
}
.icon-eh-minimize:before {
  content: '\e146';
}
.icon-eh-minus:before {
  content: '\e061';
}
.icon-eh-more:before {
  content: '\e147';
}
.icon-eh-move-horizontal:before {
  content: '\e149';
}
.icon-eh-move-horizontal-vertical:before {
  content: '\e148';
}
.icon-eh-move-vertical:before {
  content: '\e150';
}
.icon-eh-paste:before {
  content: '\e164';
}
.icon-eh-pause:before {
  content: '\e165';
}
.icon-eh-pdf:before {
  content: '\e166';
}
.icon-eh-phone:before {
  content: '\e167';
}
.icon-eh-pipe:before {
  content: '\e168';
}
.icon-eh-play:before {
  content: '\e169';
}
.icon-eh-potential:before {
  content: '\e170';
}
.icon-eh-power-on-off:before {
  content: '\e172';
}
.icon-eh-pptx:before {
  content: '\e173';
}
.icon-eh-pressure:before {
  content: '\e174';
}
.icon-eh-price:before {
  content: '\e065';
}
.icon-eh-print:before {
  content: '\e176';
}
.icon-eh-priority:before {
  content: '\e177';
}
.icon-eh-processing-1:before {
  content: '\e178';
}
.icon-eh-processing-2:before {
  content: '\e179';
}
.icon-eh-processing-3:before {
  content: '\e180';
}
.icon-eh-processing-4:before {
  content: '\e181';
}
.icon-eh-products:before {
  content: '\e182';
}
.icon-eh-quote:before {
  content: '\e183';
}
.icon-eh-radio:before {
  content: '\e184';
}
.icon-eh-radio-button-checked:before {
  content: '\e055';
}
.icon-eh-radio-button-inactive:before {
  content: '\e056';
}
.icon-eh-radio-button-unchecked:before {
  content: '\e057';
}
.icon-eh-reception-1:before {
  content: '\e185';
}
.icon-eh-resize:before {
  content: '\e195';
}
.icon-eh-return-material:before {
  content: '\e068';
}
.icon-eh-screenshot:before {
  content: '\e197';
}
.icon-eh-search:before {
  content: '\e198';
}
.icon-eh-sensor-condition-fair:before {
  content: '\e200';
}
.icon-eh-sensor-condition-good:before {
  content: '\e201';
}
.icon-eh-sensor-condition-poor:before {
  content: '\e199';
}
.icon-eh-services:before {
  content: '\e202';
}
.icon-eh-set-geo-location:before {
  content: '\e203';
}
.icon-eh-share:before {
  content: '\e204';
}
.icon-eh-show-details:before {
  content: '\e205';
}
.icon-eh-show-less:before {
  content: '\e206';
}
.icon-eh-show-more:before {
  content: '\e207';
}
.icon-eh-signal-noise-ratio:before {
  content: '\e208';
}
.icon-eh-sil:before {
  content: '\e209';
}
.icon-eh-rtf:before {
  content: '\e05c';
}
.icon-eh-rfid:before {
  content: '\e196';
}
.icon-eh-request-change:before {
  content: '\e192';
}
.icon-eh-repair:before {
  content: '\e191';
}
.icon-eh-refresh:before {
  content: '\e190';
}
.icon-eh-record:before {
  content: '\e189';
}
.icon-eh-reception-4:before {
  content: '\e188';
}
.icon-eh-reception-3:before {
  content: '\e187';
}
.icon-eh-reception-2:before {
  content: '\e186';
}
.icon-eh-zoom-1to1:before {
  content: '\e288';
}
.icon-eh-zip:before {
  content: '\e060';
}
.icon-eh-volume-flow:before {
  content: '\e276';
}
.icon-eh-uninstall:before {
  content: '\e264';
}
.icon-eh-unlink:before {
  content: '\e265';
}
.icon-eh-update:before {
  content: '\e266';
}
.icon-eh-wave-velocity:before {
  content: '\e279';
}
.icon-eh-zoom-in:before {
  content: '\e289';
}
.icon-eh-zoom-out:before {
  content: '\e293';
}
.icon-eh-zoom-mode-vertical:before {
  content: '\e292';
}
.icon-eh-zoom-mode-horizontal-vertical:before {
  content: '\e290';
}
.icon-eh-zoom-mode-horizontal:before {
  content: '\e291';
}
.icon-eh-youtube:before {
  content: '\e287';
}
.icon-eh-youku:before {
  content: '\e286';
}
.icon-eh-xslx:before {
  content: '\e05e';
}
.icon-eh-xml:before {
  content: '\e05d';
}
.icon-eh-wm:before {
  content: '\e285';
}
.icon-eh-wizard:before {
  content: '\e284';
}
.icon-eh-wireless-no-connection:before {
  content: '\e283';
}
.icon-eh-wireless-connection:before {
  content: '\e282';
}
.icon-eh-wifi:before {
  content: '\e281';
}
.icon-eh-wechat:before {
  content: '\e280';
}
.icon-eh-volume-concentration:before {
  content: '\e275';
}
.icon-eh-volume:before {
  content: '\e277';
}
.icon-eh-voltage:before {
  content: '\e274';
}
.icon-eh-visualisation:before {
  content: '\e273';
}
.icon-eh-vessel:before {
  content: '\e272';
}
.icon-eh-velocity:before {
  content: '\e271';
}
.icon-eh-user-filled:before {
  content: '\e269';
}
.icon-eh-user:before {
  content: '\e270';
}
.icon-eh-upper-limit:before {
  content: '\e268';
}
.icon-eh-upper-case:before {
  content: '\e267';
}
.icon-eh-undo:before {
  content: '\e263';
}
.icon-eh-umlauts:before {
  content: '\e262';
}
.icon-eh-twitter:before {
  content: '\e261';
}
.icon-eh-totalizer-positive:before {
  content: '\e260';
}
.icon-eh-totalizer-negative:before {
  content: '\e259';
}
.icon-eh-totalizer-both:before {
  content: '\e258';
}
.icon-eh-tools:before {
  content: '\e257';
}
.icon-eh-time:before {
  content: '\e256';
}
.icon-eh-tif:before {
  content: '\e05f';
}
.icon-eh-ticket:before {
  content: '\e058';
}
.icon-eh-temperature-low:before {
  content: '\e254';
}
.icon-eh-temperature-high:before {
  content: '\e253';
}
.icon-eh-temperature:before {
  content: '\e255';
}
.icon-eh-team:before {
  content: '\e252';
}
.icon-eh-system:before {
  content: '\e251';
}
.icon-eh-swipe:before {
  content: '\e250';
}
.icon-eh-support:before {
  content: '\e249';
}
.icon-eh-supplement-half:before {
  content: '\e248';
}
.icon-eh-sum:before {
  content: '\e227';
}
.icon-eh-submenu:before {
  content: '\e226';
}
.icon-eh-stop:before {
  content: '\e225';
}
.icon-eh-step-error:before {
  content: '\e224';
}
.icon-eh-steam:before {
  content: '\e223';
}
.icon-eh-sina-weibo:before {
  content: '\e210';
}
.icon-eh-sizing:before {
  content: '\e211';
}
.icon-eh-skip:before {
  content: '\e212';
}
.icon-eh-smiley-bad:before {
  content: '\e213';
}
.icon-eh-smiley-good:before {
  content: '\e214';
}
.icon-eh-smiley-ok:before {
  content: '\e215';
}
.icon-eh-software:before {
  content: '\e216';
}
.icon-eh-solutions:before {
  content: '\e217';
}
.icon-eh-sort:before {
  content: '\e218';
}
.icon-eh-sound:before {
  content: '\e219';
}
.icon-eh-special-characters:before {
  content: '\e220';
}
.icon-eh-spinner:before {
  content: '\e221';
}
.icon-eh-start-pause:before {
  content: '\e222';
}
.icon-eh-reception-0:before {
  content: '\e084';
}
.icon-eh-calendar-alternative:before {
  content: '\e030';
}
.icon-eh-calendar-month:before {
  content: '\e063';
}
.icon-eh-docx:before {
  content: '\e059';
}
.icon-eh-csv:before {
  content: '\e020';
}
.icon-eh-cancel:before {
  content: '\e032';
}
.icon-eh-network:before {
  content: '\e318';
}
.icon-eh-functional-unit:before {
  content: '\e319';
}
.icon-eh-recipe:before {
  content: '\e320';
}
.icon-eh-structure:before {
  content: '\e321';
}
.icon-eh-threshold-alert:before {
  content: '\e322';
}
.icon-eh-software-licenses:before {
  content: '\e323';
}
.icon-eh-api:before {
  content: '\e324';
}
.icon-eh-area-extend:before {
  content: '\e325';
}
.icon-eh-area-reduce:before {
  content: '\e326';
}
.icon-eh-analytics:before {
  content: '\e328';
}
.icon-eh-inventory:before {
  content: '\e329';
}
.icon-eh-knowledge-base:before {
  content: '\e330';
}
.icon-eh-library:before {
  content: '\e331';
}
.icon-eh-orders:before {
  content: '\e332';
}
.icon-eh-smart-systems:before {
  content: '\e333';
}
.icon-eh-software-licenses-assignment:before {
  content: '\e334';
}
.icon-eh-software-licenses-return:before {
  content: '\e335';
}
.icon-eh-value:before {
  content: '\e336';
}
.icon-eh-not-done:before {
  content: '\e337';
}
.icon-eh-save-cart:before {
  content: '\e338';
}
.icon-eh-bunkering:before {
  content: '\e339';
}
.icon-eh-dsp:before {
  content: '\e340';
}
.icon-eh-unlocked:before {
  content: '\e327';
}
.icon-eh-data-assignment:before {
  content: '\e341';
}
.icon-eh-fullscreen:before {
  content: '\e342';
}
.icon-eh-fullscreen-close:before {
  content: '\e343';
}
.icon-eh-operations:before {
  content: '\e344';
}
.icon-eh-password:before {
  content: '\e345';
}
.icon-eh-registration:before {
  content: '\e346';
}
.icon-eh-replace:before {
  content: '\e347';
}
.icon-eh-reset-configuration:before {
  content: '\e349';
}
.icon-eh-reset-factory:before {
  content: '\e350';
}
.icon-eh-restart-system:before {
  content: '\e351';
}
.icon-eh-restore-default-content:before {
  content: '\e352';
}
.icon-eh-save:before {
  content: '\e353';
}
.icon-eh-status-indicator-alarm:before {
  content: '\e354';
}
.icon-eh-status-indicator-tank-level-falling:before {
  content: '\e355';
}
.icon-eh-status-indicator-tank-level-rising:before {
  content: '\e356';
}
.icon-eh-sync-start:before {
  content: '\e357';
}
.icon-eh-table-view:before {
  content: '\e358';
}
.icon-eh-concentration:before {
  content: '\e348';
}
.icon-eh-device-certificate-renewal:before {
  content: '\e359';
}
.icon-eh-fieldservice-appointment:before {
  content: '\e360';
}
.icon-eh-production:before {
  content: '\e361';
}
.icon-eh-timeline:before {
  content: '\e362';
}
.icon-eh-auto-refresh-pause:before {
  content: '\e366';
}
.icon-eh-auto-refresh-play:before {
  content: '\e365';
}
.icon-eh-netilion-services:before {
  content: '\e364';
}
.icon-eh-softkey-sk3:before {
  content: '\e363';
}
.icon-eh-equal-to:before {
  content: '\e371';
}
.icon-eh-greater-than:before {
  content: '\e367';
}
.icon-eh-greater-than-equal-to:before {
  content: '\e368';
}
.icon-eh-less-than:before {
  content: '\e369';
}
.icon-eh-less-than-equal-to:before {
  content: '\e370';
}
