header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  padding: 0 10px;
  box-shadow: 2px 8px 12px -5px hsl(0deg 0% 63% / 75%);
  z-index: 10;
}

header h1 {
  margin: 0;
}

header .logo {
  background-image: url('../res/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 260px;
}

.edit-header {
  display: flex;
  flex-direction: row;
  height: 60px;
  box-shadow: unset;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

.edit-header .edit-return {
  min-width: 60px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
  font-size: 22px;
}

.edit-header .edit-return:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

header .content {
  margin: 15px 0;
  max-width: 1100px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.edit-header .content {
  max-width: 100%;
  margin: 0 5px;
}

.edit-header h1 {
  margin: 0;
}

header .content h1 {
  cursor: pointer;
  font-family: 'EuH', sans-serif;
}

header .links {
  height: 40px;
  max-width: 1100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

header .dropdown-container {
  margin-left: auto;
  z-index: 13;
}

header .links a {
  font-size: 20px;
  color: #000;
  border-bottom: 2px solid transparent;
  margin: 0 10px 5px 5px;
  font-weight: 200;
}

header .links a:hover {
  cursor: pointer;
  color: #007caa;
  border-bottom-color: #007caa;
}

header .active-link {
  color: #007caa !important;
  border-bottom-color: #007caa !important;
}

header .dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header .dropdown-container label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  border-bottom: 2px solid transparent;
  user-select: none;
}

header .dropdown-container label:hover {
  cursor: pointer;
  color: #007caa;
  border-bottom-color: #007caa;
}

header .dropdown-container .dropdown-arrow {
  font-size: 18px;
  margin-bottom: -2px;
  margin-left: 5px;
}

header .dropdown-container .dropdown-arrow.open {
  transform: rotate(180deg);
}

header .dropdown-container .dropdown-content {
  display: none;
  box-sizing: border-box;
}

header .dropdown-container .dropdown-content a {
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 18px;
}

header .dropdown-container .header-dropdown-active + .dropdown-content {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-width: 260px;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 30%);
  top: 33px;
}

.burger {
  width: 25px;
  height: 21px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  margin: 10px;
}

header input[type='checkbox'] {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  margin: 25px 10px 10px 10px;
  top: 0;
  opacity: 0;
  z-index: 10;
}

.burger .line1,
.burger .line2,
.burger .line3 {
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: all 0.2s ease;
}

@media screen and (max-width: 1350px) {
  header .dropdown-container .header-dropdown-active + .dropdown-content {
    right: 0;
  }
}

@media screen and (min-width: 700px) {
  header .links {
    display: flex;
    visibility: visible;
  }
  .burger {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 700px) {
  header .links {
    /* margin-top: 60px; */
    height: fit-content;
    display: none;
    visibility: hidden;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 10px;
    transform: translateX(-100vw);
    transition: all 0.3s ease;
    background-color: #fff;
  }
  header .links a {
    margin: 5px 0;
  }
  header .dropdown-container {
    margin-left: 0;
    margin: 5px 0;
  }
  header .logo {
    margin-right: 20px;
  }
  header.edit-header .logo {
    margin-right: 0;
  }
  .burger {
    display: flex;
    visibility: visible;
  }
  header input[type='checkbox']:disabled,
  header input[type='checkbox']:disabled ~ .content .burger {
    display: none;
  }
  header input[type='checkbox'],
  header input[type='checkbox'] ~ .content .burger {
    display: flex;
  }
  header input[type='checkbox']:checked ~ .content .burger .line1 {
    transform: translateY(9px) rotate(-45deg);
  }
  header input[type='checkbox']:checked ~ .content .burger .line2 {
    opacity: 0;
  }
  header input[type='checkbox']:checked ~ .content .burger .line3 {
    transform: translateY(-9px) rotate(45deg);
  }
  header input[type='checkbox']:checked ~ .links {
    display: flex;
    visibility: visible;
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  header .dropdown-container .header-dropdown-active + .dropdown-content {
    top: 40px;
    left: 0;
    right: unset;
  }
}

@media screen and (max-width: 520px) {
  header {
    border-left-width: 10px;
  }
  header .logo {
    background-image: url('../res/logo-mobile.png');
    width: 60px;
  }
}
