.cookie-banner {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: rgb(230, 236, 240);
  color: rgb(51, 51, 51);
  padding: 1em 1.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookie-banner .cookie-banner-text {
  background-color: rgb(230, 236, 240);
  color: rgb(51, 51, 51);
}

.cookie-banner .cookie-banner-link {
  padding-left: 5px;
  margin-bottom: -5px;
}

.cookie-banner .cookie-banner-button {
  min-width: 146px;
}

@media screen and (max-width: 520px) {
  .cookie-banner {
    flex-direction: column;
  }
  .cookie-banner-button {
    margin-top: 10px;
    width: 100%;
  }
}
