.lds-ring {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring.light div {
  border: 3px solid #fff;
  /* background-color: #e6ecf0; */
  border-color: #fff #fff #fff transparent;
}

.lds-ring.darker div {
  border: 3px solid #aaa;
  background-color: transparent;
  border-color: #aaa #aaa #aaa transparent;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
}

.lds-ring:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
