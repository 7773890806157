.react-toast-notifications__container,
.react-toast-notifications__toast {
  width: 100% !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.react-toast-notifications__container {
  padding: 0 !important;
}

.react-toast-notifications__container
  > *:not(.react-toast-notifications__container > *:first-child) {
  margin-top: -15px !important;
}

.react-toast-notifications__toast {
  min-height: 60px;
  height: fit-content;
  display: flex;
  align-items: center;
}

.react-toast-notifications__toast > * {
  color: white;
  font-size: 1.1rem !important;
  height: unset !important;
}

.react-toast-notifications__toast__icon-wrapper {
  display: none;
}

.react-toast-notifications__toast--error {
  background-color: #d50c2f !important;
}

.react-toast-notifications__toast--success {
  background-color: #54a931 !important;
}

.react-toast-notifications__toast--warning {
  background-color: #ffcc00 !important;
}

.react-toast-notifications__toast--info {
  background-color: #e6ecf0 !important;
}

.react-toast-notifications__toast--info > *,
.react-toast-notifications__toast--warning > * {
  color: black !important;
}

.react-toast-notifications__toast--info
  .react-toast-notifications__toast__dismiss-button
  > svg,
.react-toast-notifications__toast--warning
  .react-toast-notifications__toast__dismiss-button
  > svg {
  fill: black !important;
}

.react-toast-notifications__toast__dismiss-button > svg {
  fill: #e6ecf0 !important;
}
