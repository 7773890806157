.dropdown-content-active {
  color: #007caa !important;
}

.dropdown-container .dropdown-content a {
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 18px;
}

.dropdown-container .dropdown-content {
  display: none;
}

.dropdown-container .dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-container .dropdown-arrow {
  font-size: 18px;
  margin-bottom: -2px;
  margin-left: 5px;
}

.dropdown-container label:hover {
  cursor: pointer;
  color: #007caa;
  border-bottom-color: #007caa;
}

.dropdown-container label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  border-bottom: 2px solid transparent;
  user-select: none;
}

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.semester-drop a {
  color: #000;
}

.semester-drop a:hover {
  cursor: pointer;
  color: #007caa;
  border-bottom-color: #007caa;
}

.dropdown-container .header-dropdown-active + .dropdown-content {
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  min-width: 150px;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 30%);
  top: 155px;
}

@media screen and (max-width: 700px) {
  header .links {
    /* margin-top: 60px; */
    height: fit-content;
    display: none;
    visibility: hidden;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 10px;
    transform: translateX(-100vw);
    transition: all 0.3s ease;
    background-color: #fff;
  }
  .links a {
    margin: 5px 0;
  }
  .dropdown-container {
    margin-left: 0;
    margin: 5px 0;
  }
  input[type='checkbox']:disabled,
  input[type='checkbox']:disabled ~ .content .burger {
    display: none;
  }
  input[type='checkbox'],
  input[type='checkbox'] ~ .content .burger {
    display: flex;
  }
  input[type='checkbox']:checked ~ .content .burger .line1 {
    transform: translateY(9px) rotate(-45deg);
  }
  input[type='checkbox']:checked ~ .content .burger .line2 {
    opacity: 0;
  }
  input[type='checkbox']:checked ~ .content .burger .line3 {
    transform: translateY(-9px) rotate(45deg);
  }
  input[type='checkbox']:checked ~ .links {
    display: flex;
    visibility: visible;
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  .dropdown-container .header-dropdown-active + .dropdown-content {
    top: 120px;
  }
}
