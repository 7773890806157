.login-container {
  height: calc(100vh - 162px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-body {
  height: 100%;
  width: 100%;
}

.login-title {
  margin: 10px 0 20px 0;
  font-size: 34px;
  max-width: 1100px;
  width: 100%;
}

.login-content {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 136px);
  width: 100%;
  padding-top: 25px;
}

.login-form {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.login-form label {
  color: #333;
  margin-bottom: 5px;
  font-size: 18px;
}

.input-container {
  display: flex;
  align-items: center;
}

.iconEye {
  margin-left: -25px;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.login-container .button-group {
  max-width: 1100px;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .button-group,
  .button-group .btn {
    width: 100%;
  }

  .button-group .btn {
    max-width: 360px;
  }
}
