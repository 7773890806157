.popup-container {
  position: fixed;
  top: 120px;
  left: 0;
  height: calc(100% - 120px);
  width: 100%;
  background-color: #00000038;
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-body {
  background-color: #fff;
  max-width: 400px;
  width: 100%;
  margin: 20px;
}

.popup-body h2 {
  margin-top: 0;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.popup-body p {
  padding-left: 40px;
  padding-right: 40px;
}

.popup-body .button-group {
  border-top: 1px solid #222;
  padding: 10px 40px;
}

@media screen and (max-width: 700px) {
  .popup-container {
    top: 80px;
    height: calc(100% - 80px);
  }
}
