.plan-container {
  height: calc(100vh - 105px);
  display: flex;
}

.plan-container.azubi {
  height: auto;
}

.planView-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
}

.planView {
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 140px;
  margin-bottom: 100px;
  position: relative;
  z-index: 10;
}

.planView li {
  list-style-type: none;
}

.plan-department {
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.25);
  max-width: 750px;
  width: 100%;
  overflow: hidden;
}

.plan-department div {
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.plan-department label {
  font-size: 30px;
  margin-bottom: 20px;
}

.plan-department p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
}

.plan-department span {
  font-size: 16px;
  margin: 0 20px 20px 20px;
  overflow: hidden;
}

.plan-container.azubi .plan-department,
.plan-container.azubi .plan-department * {
  cursor: pointer;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.plan-container.admin .plan-department * {
  cursor: grab;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.plan-department:hover .planner-delete-icon {
  display: block;
}

.planner-delete-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 44px;
  padding: 10px;
}

.planner-delete-icon:hover {
  background-color: rgba(255, 255, 255, 0.35);
}

.plan-container .overplanned {
  background-color: #d50c2f;
  padding: 8px 12px;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  margin: 20px;
}

.plan-container .overplanned label {
  color: #fff;
  margin-bottom: 0;
}

.plan-refreshing {
  position: fixed;
  top: 60px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  background-color: #c4c4c42c;
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-loading {
  height: calc(100vh - 202px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Toolbox */

.toolbox-container h2 {
  position: sticky;
  background-color: #fff;
  padding: 5px 0;
  top: 0;
}

.toolbox-container {
  border-left: 1px solid #ccc;
  background-color: #fff;
  width: 25%;
  min-width: 300px;
  max-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toolbox-container.open {
  box-shadow: -10px 0px 30px -20px #000;
  animation: slideToolBoxIn 0.2s forwards;
}

.toolbox-departments {
  height: 65%;
  padding: 0 20px 20px;
  overflow-y: auto;
}

.toolbox-departments .department {
  padding: 15px 10px;
  font-size: 18px;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.toolbox-departments .department:hover {
  cursor: pointer;
}

.toolbox-plan-details {
  padding: 0 20px 20px;
  height: 35%;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.toolbox-plan-details div {
  display: flex;
  flex-direction: column;
}

.toolbox-plan-details label {
  margin-bottom: 5px;
}

.toolbox-opener {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  padding: 0 4px;
  left: -23px;
  top: calc(50% - 50px);
  height: 100px;
  width: 23px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 13;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.toolbox-opener:hover,
.toolbox-opener *:hover {
  cursor: pointer;
}

.toolbox-opener div {
  height: 80px;
  width: 1px;
  background-color: #222;
}

.plan-container .popup-container {
  z-index: 16 !important;
  top: 60px;
  height: calc(100% - 60px);
}

@media screen and (max-width: 850px) {
  .planView {
    padding-left: 90px;
    padding-right: 0;
  }
}

@media screen and (max-width: 750px) {
  .toolbox-container {
    transform: translateX(100vw);
    animation: slideToolBoxOut 0.2s forwards;
    right: 0;
    top: 60px;
    z-index: 12;
    position: fixed;
    height: calc(100% - 60px);
  }
  .planView-container {
    width: 100%;
  }
}

@keyframes slideToolBoxOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(300px);
  }
}

@keyframes slideToolBoxIn {
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0);
  }
}
