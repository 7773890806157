/* Cheyenne Sägesser */
.email-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}

.template-details {
  margin-left: 5%;
  margin-right: 5%;
  width: 50%;
}

.email-navbar {
  margin-right: 2%;
  margin-left: 5%;
}

.border {
  max-width: 2px;
  flex: 1;
  border-right: 1px solid #ccc; /* Trennlinie zwischen linkem und rechtem Bereich */
}

.email-title {
  font-size: 24px;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  cursor: pointer;
  margin-bottom: 10px;
  color: #007bff; /* Farbe für die Link-Elemente */
}

li:hover {
  text-decoration: underline;
}

.template-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.template-dropdown {
  margin: 10px;
  border: 2px;
  border-color: black;
  box-shadow: #ccc;
  color: rebeccapurple;
}

.email-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.parameter-box {
  width: 10%;
  margin-right: 5%;
}

.navigationbar-left {
  width: 30%;
}

.textarea-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 300px;
  resize: vertical;
}

.save-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.button-space {
  margin-top: 10px;
}
