.departmentView-title-icons {
  display: flex;
}

.departmentView-title-icons .title-icon {
  margin-left: 25px;
  font-size: 25px;
}

.departmentView-non-form {
  display: flex;
  flex-direction: column;
}

.non-form p {
  white-space: pre-line;
}

.non-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.non-form label {
  font-weight: 600;
}

.non-form span {
  display: flex;
  align-items: center;
}

.non-form span div {
  margin-right: 10px;
}

.departmentView-programmingLanguages {
  display: flex;
  flex-direction: row;
}

.departmentView-programmingLanguages div {
  background-color: #00597a;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 5px;
}
