.professionsOverview table tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px dashed #c3ced5;
}

.professionsOverview table tr td,
.professionsOverview table tr td:last-child {
  max-width: unset;
  border: none !important;
  min-width: unset;
}

.professionsOverview table tr td:first-child, 
.professionsOverview table tr td:last-child {
  width: 50px;
  min-width: 50px;
}

.professionsOverview-table-icon {
  cursor: pointer;
  padding: 0 4px;
  width: 30px;
  height: 25px;
}
