.grade-popup {
  background-color: rgba(170, 170, 170, 0.37);
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  padding: 0 10px;
}

.popup-content {
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 25px 35px;
  box-shadow: 2px 2px 18px -10px #bbbc;
}

.popup-content .close-x {
  display: flex;
  justify-content: flex-end;
  font-size: 23px;
}

.popup-content .close-x > * {
  cursor: pointer;
}

.grade-popup .details-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0;
}

.grade-popup .details-row label {
  font-size: 23px;
}

.grade-popup .detail-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.grade-popup .detail-info.full-width {
  width: 100%;
}

.grade-popup .details-row .detail-title {
  color: #333;
  font-weight: 800;
  font-size: 16px;
}

.grade-popup .popup-description {
  margin: 0;
}
