.multiplan {
  height: auto;
}

* {
  scroll-behavior: smooth;
}

.multiplan .planView-container {
  overflow-y: unset;
}

.multiplan .planView {
  margin-bottom: 0;
}

.multiplan .multiplan-plan-container {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.multiplan .multiplan-plan-container:not(:last-child) {
  border-right: 1px dashed #ccc;
}

.multiplan .plan-spacer {
  min-width: 140px;
  height: 100%;
}

.multiplan .multiplan-plan-container .planView {
  padding-left: 20px;
}

.multiplan .plan-department {
  max-width: unset;
}

.multiplan .plans-view {
  display: flex;
  flex-direction: row;
}

.multiplan-plan-container h3 {
  position: sticky;
  top: 0;
  margin: -20px 0 0 0;
  padding: 5px 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 100;
}

@media screen and (max-width: 850px) {
  .multiplan .planView {
    padding-left: 20px;
    padding-right: 20px;
  }
  .multiplan .plan-spacer {
    min-width: 90px;
  }
}
