footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 25px;
  max-width: 1100px;
  width: 100%;
}

.footer-body a {
  font-family: 'EuH';
  font-weight: 200;
  font-size: 14px;
}

.footer-body a::before {
  content: '| ';
  color: rgb(51, 51, 51);
}

.footer-body a:not(:last-child) {
  margin-right: 5px;
}

footer span {
  font-size: 14px;
  font-family: 'EuH';
  font-weight: 200;
  margin-right: 5px;
}
