.table {
  padding: 0;
  margin: 0;
  border-spacing: 0;
  width: 100%;
}

.table thead th {
  font-size: 20px;
  font-weight: 600;
}

.table thead th {
  border-bottom: 1px solid #c3ced5;
}

.table tr:not(:last-child) td {
  border-bottom: 1px dashed #c3ced5;
}

.table tr td {
  font-size: 18px;
}

.table > tbody > tr:nth-child(odd) {
  background-color: #f8f8f8;
}


.table th,
.table td {
  text-align: center;
  padding: 15px;
}

.table .clickable {
  cursor: pointer;
}

.table.left-align td,
.table.left-align th {
  text-align: left;
}
