.kw {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

.kw div {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;
}

.kw span {
  font-size: 10px;
  margin-top: -10px;
}

.timeline {
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px 0 20px 0;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 60px;
  z-index: 2;
}

.timeline div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline div div {
  width: 5px;
  background-color: #000;
  position: relative;
}

.timeline span {
  z-index: 1;
  background-color: #fff !important;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: -30px;
  white-space: nowrap;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  width: 70px;
}

.kw div.highlight {
  background-color: red;
  min-width: 50vw;
  width: 100%;
  height: 1px;
  position: absolute;
}

@media screen and (max-width: 850px) {
  .timeline {
    margin-left: 30px;
  }
  .timeline span {
    font-size: 10px;
    width: 50px;
    margin-left: -20px;
    padding: 2px 0;
  }
  .kw {
    padding: 40px 5px 20px 5px;
  }
}
