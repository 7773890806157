.profile-picture-row .profile-img {
  width: 140px;
  height: 140px;
  margin-top: 20px;
  background-size: cover !important;
  background-position: center center !important;
}

.form.profile-picture {
  margin-bottom: 50px;
}

.profile-picture-row {
  display: flex;
  flex-wrap: wrap;
}

.profile-picture-row .input-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}

.profile-picture-submit {
  margin-top: 20px;
}

.profileEdit-is_active input {
  height: 30px;
  width: 30px;
  margin-top: 2px;
  border-radius: 0;
}