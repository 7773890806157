.createUser-form {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
}

.createUser .button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 30px;
}

.createUser .button-group button:first-child {
  margin-right: 20px;
}

.form.userCreate-role span {
  font-size: 13px;
  font-style: italic;
  color: #555;
  margin-left: 5px;
}

.createUser .button-group {
  margin-bottom: 20px;
}